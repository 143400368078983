/*
===============
TYPOGRAPHY
===============
*/

$mdc-typography-font-family: "Roboto", sans-serif;
$font-size-big: 17px;
$font-size-normal: 15px;
$font-size-mini: 13px;
$font-size-mini-extra: 11px;

/*
===============
BOŞLUK
===============
*/

$gutter: 20px;

$mdc-layout-grid-default-gutter: (
        desktop: $gutter,
        tablet: $gutter,
        phone: $gutter / 2
);

/*
===============
HEADER - FOOTER
===============
*/

$header-background: #FFF;
$header-height: 56px;
$header-buttons-height: 57px;
$header-buttons-background: #FFF;
$footer-height: 56px;
$page-head-height: 55px;

$mdc-toolbar-row-height: $header-height;
$mdc-toolbar-mobile-row-height: $header-height;
$mdc-toolbar-mobile-landscape-row-height: $header-height;

/*
===============
SNACKBAR
===============
*/
$snackbar-default-color: #656565;
$snackbar-default-button-color: darken($snackbar-default-color, 10);
$snackbar-danger-color: $snackbar-default-color;
$snackbar-danger-button-color: darken($snackbar-danger-color, 10);
$snackbar-warning-color: $snackbar-default-color;
$snackbar-warning-button-color: darken($snackbar-warning-color, 10);
$snackbar-success-color: $snackbar-default-color;
$snackbar-success-button-color: darken($snackbar-success-color, 10);
$snackbar-text-color: white;
$snackbar-height: 80px;

/*
===============
RENKLER
density:  Renk yoğunluğu, esas rengine nazaran rengin açıklık derecesi.
          100: Esas renk
          0: Beyaz
===============
*/
$mdc-theme-primary: #2d3e50;
$mdc-theme-secondary: /*#3690b9*/
        #069;
$mdc-theme-background: #F2F2F2;
$mdc-theme-text-primary-on-primary: #FFF;
$mdc-button-ink-color: $mdc-theme-primary;

$theme-strong: #656565;
$theme-card-color: #A5A5A5;
$theme-weak: #9D9D9D;

//Textfield
$text-field-outlined-idle-border: #EEE;
$text-field-outlined-disabled-border: #EEE;
$text-field-outlined-hover-border: #9D9D9D;

$mdc-theme-primary-light: $mdc-theme-primary;
$mdc-theme-primary-dark: $mdc-theme-secondary;
$mdc-theme-secondary-light: $mdc-theme-secondary;
$mdc-theme-secondary-dark: $mdc-theme-primary;

//Divider
$divider-color: #E9E9E9;
$divider-color-strong: #CCC;

//Vurgu
$highlight-color: $mdc-theme-primary;
$highlight-box-background: density($mdc-theme-secondary, 40);
$highlight-box-color: #FFF;

//Footer
$footer-active-background: $mdc-theme-secondary;
$footer-active-color: #FFF;
$footer-color: $mdc-theme-secondary;

//Kullanıcı Resmi
$user-online-color: #0F0;
$user-offline-color: #CCC;
$user-image-change-button-background: #FFF;
$user-image-change-button-color: #6f6f6f;

// Profil
$profile-nav-background: #FFF;
$profile-nav-color: density($mdc-theme-secondary, 40);

//Üyelik Paketleri
$kalfa-background: density($mdc-theme-secondary, 70);
$uzman-background: $mdc-theme-secondary;
$paket-highlight: #FFF;
$paket-divider-strong: #FFF;
$paket-color: #FFF;

//Sepet
$sepet-toplam-background: density($mdc-theme-secondary, 20);

//Mesajlaşma
$speaker-background: #FFF;
$speaker-border-color: $divider-color;
$interlocutor-background: #E5EFF5;
$interlocutor-border-color: #E5EFF5;

/*
===============
FORM ALANLARI
===============
*/

$mdc-text-field-height: 40px;

/*
===============
KART
===============
*/

$card-background: #FFF;
$card-radius: 4px;

/*
===============
YILDIZ
===============
*/

$rate-star-deactive: #CCC;
$rate-star-active: #f93;

/*
===============
LİSTE
===============
*/

$list-item-avatar-size: 60px;
$list-item-title-color: #656565;
$list-item-secondary-color: #9D9D9D;
$list-item-title-font-weight: 500;

/*
===============
DOPİNG
===============
*/

$doping-frame-background: density($mdc-theme-secondary, 20);
$doping-frame-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
$doping-bold-font-weight: 600;
$doping-bold-font-size: 16px;
$doping-bold-color: #069;

/*
===============
KART LİSTESİ
===============
*/

$card-list-background-color: #FFF;
$card-list-header-color: $mdc-theme-primary;
$card-list-header-font-weight: 600;
$card-list-header-font-size: 14px;
$card-list-body-color: #9D9D9D;
$card-list-body-font-size: 13px;
$card-list-footer-color: $card-list-body-color;
$card-list-footer-font-size: 11px;

/*
===============
ÇEKMECE MENÜ
===============
*/

$mdc-drawer-background: #FFF;
$mdc-drawer-side-background: #F1F1F1;
$mdc-drawer-side-top-link-background: #FFF;
$mdc-drawer-side-top-link-color: $mdc-theme-primary;
$mdc-drawer-side-bottom-link-color: $mdc-theme-secondary;

/*
===============
SELECT
===============
*/

$mdc-select-background: #FFF;
$mdc-select-border-color: $text-field-outlined-idle-border;