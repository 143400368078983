.ta {
  &-center {
    text-align: center;
    justify-content: center;
  }
  &-left {
    text-align: left;
    justify-content: left;
  }
  &-right {
    text-align: right;
    justify-content: right;
  }
}

.hidden, [hidden], .uk-hidden {
  display: none !important;
}

.fw {
  &-bold {
    font-weight: 600;
  }
  &-normal {
    font-weight: 400;
  }
  &-ligth {
    font-weight: 300;
  }
}

.m {
  &-top {
    &-clear {
      margin-top: 0 !important;
    }
    &-1-2 {
      margin-top: $gutter /2;
    }
    margin-top: $gutter;
  }
  &-bottom {
    &-clear {
      margin-bottom: 0 !important;
    }
    &-1-2 {
      margin-bottom: $gutter /2;
    }
    margin-bottom: $gutter;
  }
  &-right {
    &-clear {
      margin-right: 0 !important;
    }
    &-1-2 {
      margin-right: $gutter /2;
    }
    margin-right: $gutter;
  }
  &-left {
    &-clear {
      margin-left: 0 !important;
    }
    &-1-2 {
      margin-left: $gutter /2;
    }
    margin-left: $gutter;
  }
  &-v {
    &-clear {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    &-1-2 {
      margin-bottom: $gutter /2;
      margin-top: $gutter /2;
    }
    @extend .m-top;
    @extend .m-bottom;
  }
  &-h {
    &-clear {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-1-2 {
      margin-right: $gutter /2;
      margin-left: $gutter /2;
    }
    @extend .m-right;
    @extend .m-left;
  }
  &-clear {
    margin: 0 !important;
  }
  &-auto {
    margin: auto !important;
  }
}

.p {
  &-top {
    &-clear {
      padding-top: 0 !important;
    }
    &-1-2 {
      padding-top: $gutter /2;
    }
    padding-top: $gutter;
  }
  &-bottom {
    &-clear {
      padding-bottom: 0 !important;
    }
    &-1-2 {
      padding-bottom: $gutter /2;
    }
    padding-bottom: $gutter !important;
  }
  &-right {
    &-clear {
      padding-right: 0 !important;
    }
    &-1-2 {
      padding-right: $gutter /2;
    }
    padding-right: $gutter;
  }
  &-left {
    &-clear {
      padding-left: 0 !important;
    }
    &-1-2 {
      padding-left: $gutter /2;
    }
    padding-left: $gutter;
  }
  &-v {
    &-clear {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
    &-1-2 {
      padding-bottom: $gutter /2;
      padding-top: $gutter /2;
    }
    @extend .p-top;
    @extend .p-bottom;
  }
  &-h {
    &-clear {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-1-2 {
      padding-right: $gutter /2;
      padding-left: $gutter /2;
    }
    @extend .p-right;
    @extend .p-left;
  }
  &-clear {
    padding: 0 !important;
  }
}

.vertical-align-middle, .valign-middle {
  display: flex;
  align-items: center;
  & > *:before {
    align-self: flex-start;
  }
  & > *:after {
    align-self: flex-end;
  }
}

.spread, .wide {
  width: 100%;
}

.extended {
  height: 100%;
}

.divider {
  &-bottom {
    &-strong {
      border-bottom: solid 1px $divider-color-strong;
    }
    border-bottom: solid 1px $divider-color;
  }
  &-top {
    &-strong {
      border-top: solid 1px $divider-color-strong;
    }
    border-top: solid 1px $divider-color;
  }
  &-right {
    &-strong {
      border-right: solid 1px $divider-color-strong;
    }
    border-right: solid 1px $divider-color;
  }
  &-left {
    &-strong {
      border-left: solid 1px $divider-color-strong;
    }
    border-left: solid 1px $divider-color;
  }
}

/*
===============
LETTER SPACİNG
===============
*/
.ls {
  &-tight {
    letter-spacing: -0.5px;
  }
}

/*
===============
FONT SIZE
===============
*/
.fs {
  &-big {
    font-size: $font-size-big;
  }
  &-normal {
    font-size: $font-size-normal;
  }
  &-mini {
    &-extra {
      font-size: $font-size-mini-extra !important;
    }
    font-size: $font-size-mini;
  }
}