$loading-color: #03a9f4;

/*
==========================
Ikoshowa Loader
<div class="ikoshowa-loader"></div>
==========================
 */
.ikoshowa-loader {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: $loading-color;
  border-radius: 50%;
  display: inline-block;
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0px solid white;
    transform: translate(-50%, -50%);
    animation: ikoshowa-loading 1000ms ease-out forwards infinite;
  }
}

@keyframes ikoshowa-loading {
  0% {
    border: 0px solid white;
  }

  20% {
    border: 8px solid white;
    width: 0%;
    height: 0%;
  }

  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
  }
}

/*
==========================
Martinvd Loader
<div class="martinvd-loader">
  <div class="inner one"></div>
  <div class="inner two"></div>
  <div class="inner three"></div>
</div>
==========================
 */

.martinvd-loader {
  .inner {
    &.one {
      left: 0%;
      top: 0%;
      animation: martinvd-rotate-one 1s linear infinite;
      border-bottom: 3px solid $loading-color;
    }
    &.two {
      right: 0%;
      top: 0%;
      animation: martinvd-rotate-two 1s linear infinite;
      border-right: 3px solid $loading-color;
    }
    &.three {
      right: 0%;
      bottom: 0%;
      animation: martinvd-rotate-three 1s linear infinite;
      border-top: 3px solid $loading-color;
    }
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

@keyframes martinvd-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes martinvd-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes martinvd-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

/*
==========================
Tgideas Loader
<div class='tgideas-loader'>
  <div class='bullet'></div>
  <div class='bullet'></div>
  <div class='bullet'></div>
  <div class='bullet'></div>
</div>
==========================
 */
.tgideas-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  .bullet {
    &:nth-child(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: 0.45s;
      animation-delay: 0.45s;
    }
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    background: $loading-color;
    -webkit-animation: tgideas-animIn 1s ease-in-out 0s infinite;
    animation: tgideas-animIn 1s ease-in-out 0s infinite;
  }
}

@-webkit-keyframes tgideas-animIn {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
}

@keyframes tgideas-animIn {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
}