// Fonts
@import "fonts";
// Variables
@import "functions";
@import "variables";
@import "uikit-stuff";
@import "loading-spinners";
@import "../../../node_modules/material-components-web/material-components-web.scss";
@import "@material/animation/functions";
@import "ready-made";

/*
===============
TEMEL
Her css'in olmazsa olmazı
===============
*/

html {
  height: 100%;
}

body {
  background-color: $mdc-theme-background;
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $mdc-typography-font-family;
}

form {
  width: 100%;
  &.inline {
    width: auto;
    display: inline-block;
  }
}

/*
===============
HAZIRLAR
Renkler, taglara ait hazır tanımlar, hazır sınıflar
===============
*/
.color {
  &-primary {
    color: $mdc-theme-primary;
  }
  &-secondary {
    color: $mdc-theme-secondary;
  }
  &-weak {
    color: $theme-weak;
  }
}

small {
  font-size: 11px;
  line-height: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

.highlight, .highlighted, strong {
  font-weight: 600;
  color: $mdc-theme-primary;
}

.highlight-box {
  background-color: $highlight-box-background;
  padding: #{$gutter / 4} #{$gutter / 2};
  color: $highlight-box-color;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  &.mdc-button__icon {
    line-height: unset;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

svg {
  max-width: 100%;
  width: 20px;
  height: auto;
  &:not(.original-color) * {
    fill: currentColor !important;
  }
}

.mdc-text-field__outline svg .mdc-text-field__outline-path {
  fill: transparent !important;
}

[class*='mdc-checkbox'] {
  color: transparent;
  label {
    color: #000;
  }
}

/*
===============
ANA İÇERİK KUTUSU
===============
*/

main {
  &.one-page {
    form {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      ul.uk-switcher {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        li {
          flex-grow: 1;
          flex-direction: column;
          display: flex;
          .mdc-card {
            flex-grow: 1;
          }
        }
      }
    }
  }
  & > * {
    flex-direction: column;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    .mdc-card {
      border-radius: $card-radius;
      display: flex;
      flex-grow: 0;
      flex-shrink: 1;
      &:not(:first-of-type) {
        margin-top: $gutter;
      }
    }
  }
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
}

/*
===============
KAPSAYICI
===============
*/

.wrapper {
  &-all {
    padding: $gutter $gutter;
  }
  width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;
  box-sizing: border-box;
}

/*
===============
HEADER - FOOTER
===============
*/

.mdc-toolbar {
  position: relative;
  box-shadow: 0 0 8px rgba(0, 0, 0, .2);
  width: 100%;
  &__row {
    @include mdc-toolbar-fill-color-accessible($header-background);
    height: $header-height;
  }
  &__section {
    flex-basis: auto;
    &:first-of-type a:first-of-type {
      padding-left: $gutter;
    }
    &:last-of-type a:last-of-type {
      padding-right: $gutter;
    }
  }
  &__menu-icon {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    position: relative;
    color: $mdc-theme-secondary;
    label {
      color: $mdc-theme-secondary;
      border-radius: 50%;
      font-size: 11px;
      padding: 2px;
      max-width: none;
      position: absolute;
      @extend .uk-position-center;
      &#basket-label {
        top: calc(50% - 1px);
        left: calc(50% + 2px);
        color: #fff;
        font-weight: 700;
      }
    }
    svg {
      width: 24px;
    }
    &.logo {
      padding: #{$gutter / 2};
      svg {
        width: 35px;
      }
    }
  }
  a {
    @extend .mdc-ripple-surface;
  }
  .graphic-box {
    width: 50px !important;
    height: 50px !important;
    img {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

/*
===============
FOOTER
===============
*/

footer.mdc-toolbar {
  position: fixed;
  font-size: 11px;
  font-family: $mdc-typography-font-family;
  bottom: 0;
  top: auto;
  height: $footer-height;
  .mdc-layout-grid {
    > * {
      height: 100%;
      grid-gap: 0;
      > * {
        height: 100%;
        border-right: solid 1px $divider-color;
      }
    }
    height: 100%;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .mdc-toolbar__menu-icon {
    @extend .ta-center;
    @extend .vertical-align-middle;
    color: $footer-color;
    display: inline-grid;
    height: 100%;
    width: 100%;
    padding: 0 !important;
    text-align: center;
    div {
      @extend .ta-center;
    }
    &.active {
      color: $footer-active-color;
      background-color: $footer-active-background;
    }
  }
  svg {
    display: inline-block;
    width: 25px;
  }
  .material-icons {
    display: block;
    font-size: 30px;
  }
  #newGiveOfferCount {
    @extend .uk-position-center;
    background-color: $mdc-theme-secondary;
    color: #FFF;
    left: calc(50% + 10px);
    top: calc(50% - 17px);
    border-radius: 4px;
    padding: 2px 4px;
  }
  #newMessageCount {
    @extend .uk-position-center;
    top: calc(50% - 2px);
  }
}

/*
===============
HEADER - FOOTER YER DOLDURUCULARI
===============
*/

.false-footer, .false-header {
  width: 100%;
  flex-shrink: 0;
}

.false-header {
  display: none; // flex;
  height: $header-height;
}

.false-footer {
  display: flex;
  height: $footer-height;
}

/*
===============
KART
===============
*/

.mdc-card {
  color: $theme-card-color;
  background-color: $card-background;
  min-height: 100%;
  justify-content: flex-start;
  box-shadow: none;
  &__title {
    padding-top: 34px;
    font-weight: 400;
    font-size: 17px;
    color: $theme-card-color;
    text-align: center;
    padding-bottom: 6px;
    line-height: 20px;
  }
  .card-top {
    padding: 35px 0;
    border-top: solid 1px $divider-color;
    border-bottom: solid 1px $divider-color;
  }
  &__primary {
    &:last-child {
      padding-bottom: $gutter / 2;
    }
    &.link-head {
      padding: 0;
      a {
        @extend .mdc-ripple-surface;
        display: flex;
        font-weight: 600;
        color: $mdc-theme-primary;
        font-size: 14px;
        line-height: 22px;
        padding: #{$gutter / 2} $gutter;
      }
    }
    h4 {
      font-size: 14px;
      line-height: 22px;
    }
    margin-bottom: 0;
    padding: $gutter / 2;
  }
  &__supporting-text {
    &:last-child {
      padding-bottom: $gutter / 2;
    }
    color: $theme-card-color;
    padding: $gutter;
  }
}

.mdc-card__media {
  background-size: cover;
  &::before {
    display: block;
    content: "";
  }
  &--16-9::before {
    margin-top: 56.25%;
  }
}

/*
===============
BUTON
===============
*/

.mdc-button {
  text-transform: none;
  &--raised, &--unelevated {
    @include mdc-button-ink-color($mdc-theme-text-primary-on-primary);
    width: 75%;
    &.wide {
      width: 100%;
    }
  }

  &--featureless {
    height: auto;
    width: auto;
    padding: 0;
    min-width: 0;
    line-height: normal;
  }

  &--stroked {
    width: 50%;
    line-height: 34px;
    background-color: #FFF;
    @include mdc-button-ink-color($mdc-button-ink-color);
    &.wide {
      width: 75%;
    }
    &.spread {
      width: 100% !important;
    }
    &:not(:disabled) {
      background-color: #FFF;
    }
  }
  &.secondary-filled-button:not(:disabled) {
    background-color: $mdc-theme-secondary;
  }
}

/*
===============
FORM ALANLARI
===============
*/

.mdc-text-field {
  width: 100%;
  &__outline svg {
    position: relative;
    path {
      stroke: $text-field-outlined-idle-border !important;
    }
  }
  label {
    font-family: $mdc-typography-font-family;
  }
  &--textarea {
    border: none;
    textarea {
      resize: none;
      height: auto !important;
      border-radius: 4px;
      box-sizing: border-box;
    }
    &.mdc-text-field--focused {
      border-color: transparent !important;
    }
    .counter {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-weight: 600;
      background-color: rgba(255, 255, 255, .8);
      z-index: 1;
      border-radius: 6px;
      padding: 5px 10px;
    }
  }
  &--textarea &__outline {
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 3px);
  }
  &--textarea &__input {
    border: solid 2px transparent;
  }

  &--outlined &__input:hover ~ &__idle-outline,
  &--outlined &__input:focus ~ &__idle-outline,
  &--textarea &__input:focus {
    border: 1px solid $text-field-outlined-hover-border !important;
  }
  &--outlined:not(&--invalid) &__input:hover ~ &__outline &__outline-path {
    stroke: $text-field-outlined-hover-border !important;
  }
  &.number {
    .mdc-text-field__idle-outline {
      background-color: $text-field-outlined-idle-border;
    }
    input {
      font-size: 30px;
      text-align: center;
    }
    & input.cardcode {
      font-size: 16px;
    }
  }
  &.country-code {
    background: url('/img/icons/tr.png') no-repeat 8px 17px;
    width: 65px;
    label {
      left: 30px;
    }
  }
}

.mdc-select {
  width: 100%;
  background-image: none;
  height: 40px;
  .mdc-select__surface {
    width: 100% !important;
  }
  select {
    height: 40px;
    background-color: #FFF;
    border-radius: 4px;
  }
}

/*
===============
GRİD
===============
*/

.mdc-layout-grid {
  .gap {
    &-0 {
      grid-gap: 0;
    }
    &-column {
      &-0 {
        grid-column-gap: 0;
      }
    }
    &-row {
      &-0 {
        grid-column-gap: 0;
      }
    }
  }
  &__inner-6-column {
    display: grid;
    margin: 0;
    grid-gap: 1px;
    grid-template-columns: repeat(6, minmax(0, 100%));
  }
  &__inner-6-column &__cell {
    grid-column-end: span 6;
  }
  &__inner-5-column {
    display: grid;
    margin: 0;
    grid-gap: 4px;
    grid-template-columns: repeat(5, minmax(0, 100%));
  }
  &__inner-5-column &__cell {
    grid-column-end: span 5;
  }
  &__inner-3-column {
    display: grid;
    margin: 0;
    grid-gap: 7px;
    grid-template-columns: repeat(3, minmax(0, 100%));
  }
  &__inner-3-column &__cell {
    grid-column-end: span 3;
  }
}

/*
===============
LİSTE GRİDİ
===============
*/

.mdc-grid {
  &-tile__primary {
    background-color: transparent;
  }
  &-list-3-row {
    .mdc-grid-tile {
      width: 33% !important;
    }
  }
  &-tile__title {
    font-family: $mdc-typography-font-family;
  }
  &-list.dopings {
    .mdc-button--unelevated {
      background-color: tint($mdc-theme-secondary, 60%);
    }
    .mdc-grid-tile {
      .primary {
        text-align: center;
        img {
          height: auto;
          width: 60%;
          margin: auto;
        }
      }
      &__title {
        white-space: normal;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
      }
      a {
        height: 100%;
      }
      box-sizing: border-box;
      border-right: 1px solid $divider-color;
      &:last-child {
        border-right: 0;
      }
    }
  }
}

/*
===============
TAB
===============
*/

.mdc-tab-bar {
  text-transform: none;
}

.uk-slider-items.one-item-per-page {
  li {
    width: 100%;
  }
}

.uk-slider-tab {
  a {
    line-height: 45px;
    display: inline-block;
    border-bottom: solid 2px $mdc-theme-primary;
  }
  text-align: center;
  margin: 0;
  width: 100%;
}

.uk-slider-list {
  .mdc-list-item {
    box-sizing: border-box;
    &:not(:last-of-type) {
      border-bottom: solid 1px $divider-color;
    }
  }
  position: relative;
  .next, .prev {
    top: 10px;
    position: absolute;
    z-index: 1;
  }
  .next {
    right: 0;
  }
  .prev {
    left: 0;
  }
}

/*
===============
YILDIZ
===============
*/

.rate-stars {
  i {
    &.active {
      color: $rate-star-active;
    }
    font-size: 12px;
    color: $rate-star-deactive;
    text-indent: -3px;
  }
  padding-left: 3px;
}

/*
===============
LİSTE
===============
*/

.mdc-list {
  &-divider {
    margin: 0 20px;
    border-bottom-color: $divider-color;
  }
  &--avatar-list {
    li:not(.mdc-list-divider) {
      padding: #{$gutter / 2} $gutter;
    }
  }
  &:not(.mdc-drawer__content,.mdc-simple-menu__items) {
    .mdc-list-item {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &-item {
    &.doping-frame { //Çerçeve dopingi
      background-color: $doping-frame-background;
      box-shadow: $doping-frame-shadow;
      //height: 90px;
    }
    &.doping-bold { //Kalın Yazı Dopingi
      strong, .title {
        font-weight: $doping-bold-font-weight;
        color: $doping-bold-color;
        font-size: $doping-bold-font-size;
      }
      .mdc-list-item__secondary-text, .secondary {
        font-weight: $doping-bold-font-weight;
        color: $doping-bold-color;
      }
    }
    &__graphic {
      width: $list-item-avatar-size !important;
      height: $list-item-avatar-size !important;
      margin-right: 0 !important;
    }
    &__text {
      color: $list-item-secondary-color !important;
      max-width: calc(100% - 70px);
      strong, .title {
        color: $list-item-title-color;
        font-weight: $list-item-title-font-weight;
        font-size: 14px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .mdc-list-item__secondary-text, .secondary {
      color: $list-item-secondary-color;
      font-size: 14px;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/*
===============
KULLANICI RESMİ
Listede ve hesabım sayfalarında kullanılıyor
===============
*/

.graphic-box {
  position: relative;
  margin-right: $gutter / 2;
  height: $list-item-avatar-size !important;
  width: 60px;
  img {
    border-radius: 50%;
  }
  .line {
    &.on {
      background-color: $user-online-color;
    }
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $user-offline-color;
  }
  .change {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    button {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $user-image-change-button-background;
      text-align: center;
      box-shadow: 0 0 3px rgba(0, 0, 0, .5);
      padding: 2px 2px;
      box-sizing: border-box;
      border: none;
    }
    i {
      color: $user-image-change-button-color;
      font-size: 10px;
      margin: auto;
      line-height: 16px;
    }
  }
}

/*
===============
UYARI KUTUSU
===============
*/

.mdc-snackbar {
  &.danger {
    background-color: $snackbar-danger-color;
    button {
      background-color: $snackbar-danger-button-color;
    }
  }
  &.success {
    background-color: $snackbar-success-color;
    button {
      background-color: $snackbar-success-button-color;
    }
  }
  &.warning {
    background-color: $snackbar-warning-color;
    button {
      background-color: $snackbar-warning-button-color;
    }
  }
  .snackbar-text {
    h4 {
      display: flex;
      flex-grow: 0;
      flex-shrink: 1;
      margin: 0;
      font-family: $mdc-typography-font-family;
    }
    div {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      height: auto;
    }
    height: $snackbar-height - $gutter;
    display: flex;
    flex-direction: column;
  }
  &__action-wrapper {
    padding-left: 0;
  }
  button {
    &::before {
      display: block;
      width: 35px;
      height: 35px;
      content: "";
      position: relative;
      border: solid 3px $snackbar-text-color;
      border-radius: 50%;
      top: -7px;
      left: 19px;
      transform-origin: center center;
      margin-bottom: -50%;
      margin-right: -50%;
    }
    background-color: $snackbar-default-button-color;
    color: $snackbar-text-color;
    height: $snackbar-height;
    width: $snackbar-height;
    margin-right: $gutter;
    @extend .material-icons;
  }
  background-color: $snackbar-default-color;
  color: $snackbar-text-color;
  padding-left: 0;
  z-index: 6;
}

/*
===============
HİZMET AL ve HİZMET VER ALANI
===============
*/

.header-buttons {
  a {
    &:first-of-type {
      border-radius: 5px;
      margin-right: $gutter / 2;
    }
    &:last-of-type {
      margin-left: $gutter / 2;
      border-radius: 5px;
    }
    margin: 0;
    border: solid 1px $mdc-theme-primary;
    width: calc(50% - #{($gutter / 2) + 2});
    display: inline-block;
  }
  background-color: $header-buttons-background;
  height: $header-buttons-height;
  padding-top: $gutter / 2;
  padding-bottom: $gutter / 2;
}

/*
===============
KATEGORİ BAŞLIKLARI
===============
*/

h1.page-head {
  background-color: $mdc-theme-background;
  margin: 0;
  text-align: center;
  font-family: $mdc-typography-font-family;
  padding-top: #{$gutter};
  padding-bottom: #{$gutter};
  font-size: 15px;
  flex-grow: 0;
  color: $theme-strong;
  height: #{$page-head-height - ($gutter * 2)};
}

/*
===============
KATEGORİ SAYFASINDAKİ FİLTRE ALANLARI
===============
*/

.filter {
  .button {
    a {
      &.active {
        background-color: $mdc-theme-secondary !important;
      }
      font-size: 11px;
      background-color: tint($mdc-theme-secondary, 40%) !important;
    }
    &:not(:first-of-type) a {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    &:not(:last-of-type) a {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

/*
===============
FLEX TABLO
===============
*/

.flex-row {
  display: flex;
  flex-direction: row;
  .grow {
    flex-grow: 1;
    flex-shrink: 0;
  }
  > *, .shrink {
    flex-grow: 0;
    flex-shrink: 1;
  }
  &-list {
    &.with-head {
      margin-top: $gutter / 4;
      border-top: solid 1px $divider-color-strong;
    }
    & > * {
      &:last-child {
        border-bottom: none;
      }
      border-bottom: solid 1px $divider-color;
      padding: #{$gutter / 2} 0;
    }
    & > * > *:first-child {
      color: $theme-weak;
      font-size: 13px;
      a:not('.mdc-list-item') {
        font-size: 13px;
        display: inline-block;
        margin: -10px 0;
        line-height: 39px;
        color: $theme-weak;
        width: 100%;
        text-decoration: none;
      }
    }
    & > a {
      @extend .mdc-ripple-surface;
      font-size: 13px;
      display: flex;
    }
    & > .divider {
      padding: 0;
      height: 1px;
      background-color: $divider-color;
    }
    .highlight-box {
      width: 21px;
      text-align: center;
    }
    &.link-list {
      & > * {
        border-bottom: none;
      }
      & > a {
        padding-left: $gutter;
        padding-right: $gutter;
        &:first-of-type {
          padding-top: $gutter;
        }
        &:last-of-type {
          padding-bottom: $gutter;
          & + .divider {
            display: none;
          }
        }
      }
      & > .divider {
        margin-left: $gutter;
        margin-right: $gutter;
      }
      &.with-head {
        border-top: none;
        & > a:first-of-type {
          padding-top: #{$gutter / 2};
        }
        .divider-strong {
          padding: 0;
          height: 1px;
          background-color: $divider-color-strong;
          margin-left: $gutter;
          margin-right: $gutter;
        }
      }
    }
  }
}

.grow {
  flex-grow: 1 !important;
}

.flex-container {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
}

/*
===============
PROFİL
===============
*/

.profile {
  &-head {
    font-size: 13px;
    .rate-stars {
      padding-top: $gutter / 4;
    }
    .favorite {
      display: inline-flex;
      &-button {
        color: #de442c;
      }
    }
  }
  &-nav {
    border-radius: 6px;
    background-color: $profile-nav-background;
    margin: 0;
    padding: 0;
    * > *:first-of-type > a {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    * > *:last-of-type > a {
      border-right: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    a {
      width: 100%;
      padding: 10px 0;
      color: $profile-nav-color !important;
      border-radius: 0;
      border-right: 1px solid $divider-color;
      svg {
        width: 30px;
      }
    }
  }
}

/*
===============
TABBAR
===============
*/

[class*='tabbar'] {
  .mdc-tab--active {
    color: $mdc-theme-secondary;
  }
  div {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #FFF;
  }
  .mdc-tab-bar__indicator {
    background-color: $mdc-theme-secondary;
  }
}

/*
===============
SLİDESHOW
===============
*/

.uk-slideshow {
  .uk-dotnav {
    justify-content: center;
    position: relative;
    top: -#{$gutter};
    margin-bottom: -10px;
  }
}

/*
===============
TALEPLERİM
===============
*/

.campaign-tabbar {
  a {
    font-size: 12px;
    padding: 10px 3px;
    text-indent: -2px;
    min-width: auto;
  }
}

/*
===============
KART LİSTESİ
===============
*/

.card-list {
  background-color: $card-list-background-color;
  .card {
    border-bottom: solid 1px $divider-color;
    padding: #{$gutter} 0;
    .header {
      color: $card-list-header-color;
      font-weight: $card-list-header-font-weight;
      font-size: $card-list-header-font-size;
      .highlight-box {
        font-size: $card-list-header-font-size - 4;
        padding: 4px 6px;
      }
    }
    .body {
      padding-top: #{$gutter / 4};
      color: $card-list-body-color;
      font-size: $card-list-body-font-size;
      //text-align: justify;
    }
    .footer {
      padding-top: #{$gutter / 4};
      font-size: $card-list-footer-font-size;
      i {
        font-size: $card-list-footer-font-size;
      }
      .divider {
        border-right: solid 2px $divider-color;
        margin: 0 #{$gutter / 4};
      }
      .highlight-box {
        font-size: $card-list-footer-font-size - 1;
        padding: 4px 6px;
        border-width: 1px;
      }
    }
    .bottom-buttons {
      padding: #{$gutter / 2} $gutter 0 $gutter;
      .mdc-button {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        border-width: 1px;
      }
    }
    .highlight-box {
      background-color: $highlight-box-color;
      color: $highlight-box-background;
      border: solid 2px $highlight-box-background;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

/*
===============
ÇEKMECE MENÜ
===============
*/
.mdc-drawer {
  @include mdc-drawer-fill-color($mdc-drawer-background);
  .mdc-drawer__drawer {
    display: flex;
    flex-direction: row;
    header {
      a {
        padding: #{$gutter / 2};
        border-bottom: 1px solid $divider-color-strong;
      }
    }
    .side {
      flex-shrink: 1;
      flex-grow: 0;
      width: 64px;
      display: flex;
      flex-direction: column;
      background-color: $mdc-drawer-side-background;
      .top {
        flex-grow: 1;
        li {
          padding: #{$gutter / 2} 0;
        }
        a {
          background-color: $mdc-drawer-side-top-link-background;
          border-radius: 50%;
          color: $mdc-drawer-side-top-link-color;
          width: 40px;
          height: 40px;
          padding: 8px 5px 5px;
          display: inline-block;
          box-sizing: border-box;
          @extend .mdc-ripple-surface;
          svg {
            width: 24px;
          }
        }
      }
      .bottom {
        flex-grow: 0;
        flex-shrink: 0;
        li {
          padding: 0;
        }
        a {
          display: inline-block;
          width: 100%;
          height: 24px;
          padding: #{$gutter / 2} 0;
          color: $mdc-drawer-side-bottom-link-color;
          @extend .mdc-ripple-surface;
          svg {
            width: 24px;
          }
        }
      }
      ul {
        list-style: none none;
        padding: 0;
        margin: 0;
        li {
          padding: #{$gutter / 4} 0;
          text-align: center;
        }
      }
    }
    .main {
      overflow-y: hidden;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      .mdc-drawer__content {
        padding: 0;
        overflow-y: hidden;
        flex-shrink: 0;
        flex-grow: 1;
        height: calc(100% - 82px);
      }
      .mdc-list {
        a {
          height: 10%;
          border-bottom: 1px solid $divider-color;
        }
      }
    }
  }
}

/*
===============
SELECT
===============
*/

.one-page-form-select .mdc-select__menu {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  max-height: none !important;
  width: 100% !important;
}

.mdc-select {
  &__surface {
    padding: 0 10px;
    height: 45px;
    line-height: 20px;
    background-color: $mdc-select-background;
    border-radius: 4px;
    border: solid 2px $mdc-select-border-color;
  }
  &:after {
    @extend .material-icons;
    content: 'keyboard_arrow_down';
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__label {
    left: 10px;
  }
  &__bottom-line {
    display: none;
  }
  &__menu {
    z-index: 5;
  }
}

/*
===============
SAYFA İÇİ UYARI
===============
*/

.full-page-warning {
  background: url("/img/warning/bg.png") no-repeat center;
  min-height: 188px;
  min-width: 188px;
  position: relative;
  img {
    @extend .uk-cover;
  }
  & + h3 {
    font-weight: normal;
  }
}

/*
===============
AKORDİYON LİSTE
===============
*/

.uk-accordion {
  &-title {
    position: relative;
    width: calc(100% - 1.4em);
    overflow: visible;
    &::after {
      top: 0;
      position: absolute;
      right: -1.4em;
    }
  }
  &.without-icon {
    &-title::after {
      display: none;
    }
  }
  & > li:not(:last-of-type) {
    border-bottom: 1px solid $divider-color;
    padding-bottom: $gutter / 2;
  }
}

/*
===============
PAKETLER
===============
*/

.paket {
  img {
    width: 45px;
    height: 45px;
  }
  &.kalfa, &.uzman {
    .highlight {
      color: $paket-highlight;
    }
    .flex-row-list.with-head {
      border-top-color: $paket-divider-strong;
    }
    .mdc-button.mdc-button--featureless {
      color: $paket-color;
    }
  }
  .flex-row > div:not(:first-of-type) {
    padding-left: $gutter / 2;
  }
  &.kalfa {
    background-color: $kalfa-background;
  }
  &.uzman {
    .mdc-button--unelevated {
      background-color: #FFF;
      color: $mdc-theme-primary;
    }
    background-color: $uzman-background;
  }
  .mdc-select__surface {
    width: auto !important;
    .mdc-select__selected-text {
      width: calc(100% - 20px);
      text-overflow: ellipsis;
    }
  }
  .mdc-card__supporting-text {
    .flex-row {
      & > * {
        box-sizing: border-box;
      }
      & > *:nth-of-type(1) {
        width: 45px;
      }
      & > *:nth-of-type(2) {
        width: calc(100% - 145px);
      }
      & > *:nth-of-type(3) {
        width: 100px;
      }
    }
  }
}

/*
===============
SEPET
===============
*/

.sepet {
  .flex-row-list {
    & > div:nth-child(odd) {
      border-bottom: none;
      padding-top: $gutter;
    }
    & > div:nth-child(even) {
      padding-bottom: $gutter;
    }
    .flex-row {
      & > div {
        &:not(:first-of-type) {
          padding-left: $gutter / 2;
        }
      }
    }
    .price {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .toplam {
    padding: #{$gutter / 2} #{$gutter / 2};
    background-color: $sepet-toplam-background;
  }
}

/*
===============
TABLO
Siparişlerim sayfasında kullanılmak üzere yazıldı
===============
*/

table {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      border-bottom: solid 1px $divider-color-strong;
      th {
        font-size: 14px;
        font-weight: 600;
        color: $mdc-theme-primary;
        text-align: center;
        padding: 0 0 #{$gutter / 2} 0;
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
  tbody {
    &:not(:last-of-type) tr:last-of-type {
      border-bottom: solid 1px $divider-color;
      td {
        padding-bottom: $gutter / 2;
      }
    }
    &:last-of-type tr:last-of-type {
      border-bottom: none;
    }
    tr {
      border-bottom: solid 1px $divider-color;
      &:last-of-type td {
        border-bottom: none;
        padding-bottom: 0;
      }
      td {
        font-size: 13px;
        text-align: center;
        padding: #{$gutter / 2} 0;
        &:first-of-type {
          text-align: left;
        }
        &:last-of-type {
          text-align: right;
        }
        color: $theme-weak;
      }
    }
  }
  .loaderContainer td {
    text-align: center !important;
  }
  .mdc-button {
    padding: 2px #{$gutter / 2};
    line-height: 25px;
    min-width: 0 !important;
    width: auto !important;
    height: auto !important;
  }
}

input.cardmonth,
input.ccv,
input.cardyear {
  font-size: 20px;
  text-align: center;
}

/*
===============
BİLDİRİM SAYFASI
===============
*/

.notification-head {
  position: relative;
  a {
    position: absolute;
    right: $gutter;
    top: calc(50% - 9px);
    font-size: 15px;
  }
}

/*
===============
ARAMA SAYFASI
===============
*/

aside.search {
  .mdc-dialog__surface {
    position: absolute;
    top: $gutter / 2;
    transform: translateY(0) scale(1, 0);
    transform-origin: top left;
  }
  &.mdc-dialog--open .mdc-dialog__surface {
    transform: translateY(0) scale(1, 1);
  }
  .mdc-text-field {
    input {
      border-bottom: none;
    }
    .mdc-line-ripple {
      display: none;
    }
    margin-top: 5px !important;
  }
}

header.search {
  .mdc-text-field {
    input {
      border-bottom: none;
    }
    .mdc-line-ripple {
      display: none;
    }
    margin-top: 5px !important;
  }
}

/*
===============
MESAJLAŞMA SAYFASI
===============
*/

.message {
  padding: $gutter;
  display: flex;
  flex-direction: column;
  .container {
    flex-grow: 1;
    overflow: auto;
    & > div > div {
      padding-top: #{$gutter/4};
      padding-bottom: #{$gutter/4};
      & > span {
        padding: #{$gutter/2};
        display: inline-block;
        border-radius: 6px;
        position: relative;
        max-width: 80%;
        /*&::before {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          top: 10px;
          display: block;
          width: 8px;
          height: 6px;
          content: '\00a0';
          -webkit-transform: rotate(29deg) skew(-35deg);
          transform: rotate(29deg) skew(-35deg);
        }*/
      }
    }
  }
  .gun {
    position: relative;
    span {
      font-size: 14px;
      background-color: #F5F5F5;
      z-index: 1;
      border-left: solid 5px #FFF;
      border-right: solid 5px #FFF;
    }
    [class*='divider'] {
      position: absolute;
      width: 100%;
      top: 50%;
      padding: 0;
      z-index: 0;
    }
  }
  .you {
    div {
      justify-content: left;
      text-align: left;
      span {
        border: solid 1px $speaker-border-color;
        background-color: $speaker-background;
        /*&:before {
          left: -6px;
          background-color: $speaker-background;
          border: solid 1px $speaker-border-color;
        }*/
      }
    }
  }
  .me {
    div {
      text-align: right;
      justify-content: right;
      span {
        &:before {
          right: -6px;
          background-color: $interlocutor-background;
          border: solid 1px $interlocutor-border-color;
        }
        border: solid 1px $interlocutor-border-color;
        background-color: $interlocutor-background;
        color: #000;
      }
    }
  }
  .send {
    margin-left: $gutter / 2;
    border-radius: 50%;
    min-width: 56px;
    width: 56px;
    padding: 0;
    height: 56px;
  }
}

/*
===============
GİRİŞ LİSTESİ
Çok fazla alt alta input olduğu zaman kullanılıyor
===============
*/

.input-list {
  .mdc-text-field {
    margin-top: #{$gutter / 4} !important;
    margin-bottom: #{$gutter / 4} !important;
  }
  .mdc-select {
    margin-top: #{$gutter / 4 + 5} !important;
    margin-bottom: #{$gutter / 4 + 5} !important;
  }
  /*
  Bu geçici gibi görünen çözümü istenen koşullara bağlı olarak değiştirebilirsin
  */
  form > *:first-of-type {
    margin-top: $gutter !important;
  }
}

/*
===============
YAPTIĞIM İŞLERİ DÜZENLE
===============
*/

@keyframes change-works-cover {
  from {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  to {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@keyframes change-works-uncover {
  from {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  to {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}

@keyframes change-works-slideUp {
  from {
    transform: translateY(20px);
    visibility: hidden;
  }

  to {
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes change-works-slideDown {
  from {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }

  to {
    transform: translateY(20px);
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes change-works-unvisible {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes change-works-visible {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

/*
===============
YAPTIĞIM İŞLERİ DÜZENLE
===============
*/

.change-works {
  //noinspection CssInvalidFunction
  .delete {
    padding: #{$gutter / 4};
    background-color: rgba($mdc-theme-secondary, .8);
    color: #FFF;
    border: solid 1px rgba(255, 255, 255, .5);
    display: flex;
    animation: mdc-animation-enter(change-works-uncover, 350ms, 350ms);
    animation-fill-mode: forwards;
    width: 100%;
    height: 100%;
    .delete-button {
      @extend .uk-position-center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      padding-top: 5px;
      z-index: 1;
      transform-origin: center;
      animation: mdc-animation-enter(change-works-visible, 350ms);
      animation-fill-mode: forwards;
      width: 100%;
      height: 100%;
      svg {
        @extend .uk-position-center;
        position: absolute;
        animation: mdc-animation-enter(change-works-visible, 350ms);
        animation-fill-mode: forwards;
      }
    }
    .confirmation {
      animation: mdc-animation-enter(change-works-slideDown, 350ms);
      animation-fill-mode: forwards;
      visibility: hidden;
      color: #FFF;
      position: absolute;
      a {
        padding-top: 2px;
        box-sizing: border-box;
        color: #FFF;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
        border: solid 1px #FFF;
        background-color: $mdc-theme-secondary;
      }
    }
    &.cover {
      animation: mdc-animation-enter(change-works-cover, 350ms);
      animation-fill-mode: forwards;
      .delete-button {
        animation: mdc-animation-enter(change-works-unvisible, 350ms);
        animation-fill-mode: forwards;
        transform-origin: center;
        svg {
          animation: mdc-animation-enter(change-works-unvisible, 350ms);
          animation-fill-mode: forwards;
        }
      }
      .confirmation {
        animation: mdc-animation-enter(change-works-slideUp, 350ms, 350ms);
        animation-fill-mode: forwards;
      }
    }
  }
  .uk-cover-container {
    height: 90px;
    position: relative;
  }
}

.comment-stars {
  text-align: center;
  display: block;
  width: 100%;
}

.comment-stars i {
  font-size: 32px;
}

/*
===============
YER DOLDURUCU RESİM
===============
*/

img.filler {
  opacity: 0.4;
}