/* ========================================================================
   Component: Position
 ========================================================================== */
/* Directions
 ========================================================================== */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
}

/* Edges
 ========================================================================== */
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position {
  &-top {
    top: 0;
    left: 0;
    right: 0;
    &-left {
      top: 0;
      left: 0;
    }
    &-right {
      top: 0;
      right: 0;
    }
    &-center {
      top: 0;
    }
  }
  &-bottom {
    bottom: 0;
    left: 0;
    right: 0;
    &-left {
      bottom: 0;
      left: 0;
    }
    &-right {
      bottom: 0;
      right: 0;
    }
    &-center {
      bottom: 0;
    }
  }
  &-left {
    top: 0;
    bottom: 0;
    left: 0;
  }
  &-right {
    top: 0;
    bottom: 0;
    right: 0;
  }
  &-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: -moz-max-content;
    max-width: 100%;
    box-sizing: border-box;
    &-left {
      left: 0;
      &-out {
        right: 100%;
        width: max-content;
      }
    }
    &-right {
      right: 0;
      &-out {
        left: 100%;
        width: max-content;
      }
    }
  }
}

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: 50%;
  transform: translateY(-50%);
}

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: 50%;
  transform: translateX(-50%);
  display: table;
  width: -moz-max-content;
  max-width: 100%;
  box-sizing: border-box;
}

/* Margin modifier
 ========================================================================== */
/*
 * Small
 */
.uk-position-small {
  margin: 15px;
  &.uk-position-center {
    transform: translate(-50%, -50%) translate(-15px, -15px);
  }
  &[class*='uk-position-center-left'],
  &[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-15px);
  }
  &.uk-position-top-center,
  &.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-15px);
  }
}

/*
 * Medium
 */
.uk-position-medium {
  margin: 30px;
  &.uk-position-center {
    transform: translate(-50%, -50%) translate(-30px, -30px);
  }
  &[class*='uk-position-center-left'],
  &[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-30px);
  }
  &.uk-position-top-center,
  &.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-30px);
  }
}

/*
 * Large
 */
.uk-position-large {
  margin: 30px;

  &.uk-position-center {
    transform: translate(-50%, -50%) translate(-30px, -30px);
  }

  &[class*='uk-position-center-left'],
  &[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-30px);
  }

  &.uk-position-top-center,
  &.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-30px);
  }
}

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
  }
  .uk-position-large.uk-position-center {
    transform: translate(-50%, -50%) translate(-50px, -50px);
  }
  .uk-position-large[class*='uk-position-center-left'],
  .uk-position-large[class*='uk-position-center-right'] {
    transform: translateY(-50%) translateY(-50px);
  }
  .uk-position-large.uk-position-top-center,
  .uk-position-large.uk-position-bottom-center {
    transform: translateX(-50%) translateX(-50px);
  }
}

/* ========================================================================
   Component: Slider
 ========================================================================== */
.uk-slider-items {
  & > * {
    flex: none;
    position: relative;
  }
  will-change: transform;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-touch-callout: none;
  height: 100%;
  width: 100%;
}

.uk-slider-container {
  overflow: hidden;
}

.uk-switcher {
  & > :not(.uk-active) {
    display: none !important;
  }
  & > * {
    height: 100%;
  }
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%;
}

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
.uk-slideshow {
  -webkit-tap-highlight-color: transparent;
}

.uk-slideshow-items {
  position: relative;
  z-index: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  -webkit-touch-callout: none;
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    will-change: transform, opacity;
    touch-action: pan-y;
    &:not(.uk-active) {
      display: none;
    }
  }
}

/* Custom controls
 ========================================================================== */
.uk-form-custom {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
}

.uk-form-custom select,
.uk-form-custom input[type="file"] {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-appearance: none;
  opacity: 0;
  cursor: pointer;
}

.uk-form-custom input[type="file"] {
  font-size: 500px;
  overflow: hidden;
}

/* ========================================================================
   Component: Sticky
 ========================================================================== */
.uk-sticky-fixed {
  z-index: 980;
  box-sizing: border-box;
  margin: 0 !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s;
}

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s;
}

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
.uk-dotnav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: -12px;
  & > * {
    flex: none;
    padding-left: 12px;
  }

  /* Items
   ========================================================================== */
  & > * > * {
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: transparent;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid rgba(102, 102, 102, 0.4);
    transition: 0.2s ease-in-out;
    transition-property: background-color, border-color;

    &:hover, &:focus {
      background-color: rgba(102, 102, 102, 0.6);
      outline: none;
      border-color: transparent;
    }
    &:active {
      background-color: rgba(102, 102, 102, 0.2);
      border-color: transparent;
    }
  }
  & > .uk-active > * {
    background-color: rgba(102, 102, 102, 0.6);
    border-color: transparent;
  }
  /* Modifier: 'uk-dotnav-vertical'
   ========================================================================== */
  &.uk-dotnav-vertical {
    & * {
      padding-left: 0;
      padding-top: 12px;
    }
    flex-direction: column;
    margin-left: 0;
    margin-top: -12px;
  }

}

/* ========================================================================
   Component: Cover
 ========================================================================== */
.uk-cover {
  max-width: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

iframe.uk-cover {
  pointer-events: none;
}

/* Container
 ========================================================================== */
.uk-cover-container {
  overflow: hidden;
  position: relative;
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none;
  /* Item
   ========================================================================== */
  & > :nth-child(n+2) {
    margin-top: 20px;
  }

  /* Title
   ========================================================================== */
  &-title {
    display: block;
    //font-size: 1.25rem;
    line-height: 1.4;
    color: #333;
    overflow: hidden;
    &::after {
      content: "";
      width: 1.4em;
      height: 1.4em;
      float: right;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    /* Hover + Focus */
    &:hover,
    &:focus {
      color: #666;
      text-decoration: none;
      outline: none;
    }
  }

  /* Content
   ========================================================================== */
  &-content {
    margin-top: 20px;

    &::before, &::after {
      content: "";
      display: table;
    }

    &-content::after {
      clear: both;
    }
  }
}

.uk-open > .uk-accordion-title::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23666%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.uk-accordion-content > :last-child {
  margin-bottom: 0;
}

/* ========================================================================
   Component: Spinner
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
/* SVG
 ========================================================================== */
.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

/*
 * Circle
 */
.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}

[class*='uk-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

/* ========================================================================
   Component: Animation
 ========================================================================== */

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

/* Animations for scrollspy
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade-scale-02;
}

.uk-animation-scale-down {
  animation-name: uk-fade-scale-18;
}

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade-top;
}

.uk-animation-slide-bottom {
  animation-name: uk-fade-bottom;
}

.uk-animation-slide-left {
  animation-name: uk-fade-left;
}

.uk-animation-slide-right {
  animation-name: uk-fade-right;
}

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade-top-small;
}

.uk-animation-slide-bottom-small {
  animation-name: uk-fade-bottom-small;
}

.uk-animation-slide-left-small {
  animation-name: uk-fade-left-small;
}

.uk-animation-slide-right-small {
  animation-name: uk-fade-right-small;
}

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade-top-medium;
}

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade-bottom-medium;
}

.uk-animation-slide-left-medium {
  animation-name: uk-fade-left-medium;
}

.uk-animation-slide-right-medium {
  animation-name: uk-fade-right-medium;
}

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-scale-kenburns;
  animation-duration: 15s;
}

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake;
}

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s;
}

/* Enable animation only on hover
========================================================================== */
/*
 * Note: Firefox and IE needs this because animations are not triggered when switching between display `none` and `block`
 */
.uk-animation-toggle:not(:hover):not(.uk-hover) [class*='uk-animation-'] {
  animation-name: none;
}

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
 * Slide Top
 */
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Bottom
 */
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Left
 */
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Slide Right
 */
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Slide Top Small
 */
@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Bottom Small
 */
@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Left Small
 */
@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Slide Right Small
 */
@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Slide Top Medium
 */
@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Bottom Medium
 */
@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*
 * Slide Left Medium
 */
@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Slide Right Medium
 */
@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*
 * Scale Up
 */
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*
 * Scale Down
 */
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*
 * Kenburns
 */
@keyframes uk-scale-kenburns {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

/*
 * Shake
 */
@keyframes uk-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}